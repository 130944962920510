import { useState, useMemo, useEffect } from "react";
import { generateMaze, solve } from "./Util";
import "./styles.scss";
import { RightArrowIcon } from "../../components/RightArrowIcon";
import { LeftArrowIcon } from "../../components/LeftArrowIcon";
import { DownArrowIcon } from "../../components/DownArrowIcon";
import { UpArrowIcon } from "../../components/UpArrowIcon";

export default function Maze() {
  const minSize = 10;
  const maxSize = 20;
  const [gameId, setGameId] = useState(1);
  const [status, setStatus] = useState("playing");
  const [size, setSize] = useState(20);
  const [cheatMode, setCheatMode] = useState(false);
  const [userPosition, setUserPosition] = useState([0, 0]);
  const maze = useMemo(() => generateMaze(size, size), [size, gameId]);
  const solution = useMemo(() => {
    const s = new Set();
    const solutionPath = solve(maze, userPosition[0], userPosition[1]);
    solutionPath.forEach((path) => {
      const [x, y] = path;
      s.add(String(x) + "-" + String(y));
    });
    return s;
  }, [size, userPosition[0], userPosition[1], gameId]);

  useEffect(() => {
    const lastRowIndex = maze.length - 1;
    const lastColIndex = maze[0].length - 1;
    if (userPosition[0] === lastRowIndex && userPosition[1] === lastColIndex) {
      setStatus("won");
    }
  }, [userPosition[0], userPosition[1]]);

  const makeClassName = (i, j) => {
    const rows = maze.length;
    const cols = maze[0].length;
    let arr = [];
    if (maze[i][j][0] === 0) {
      arr.push("topWall");
    }
    if (maze[i][j][1] === 0) {
      arr.push("rightWall");
    }
    if (maze[i][j][2] === 0) {
      arr.push("bottomWall");
    }
    if (maze[i][j][3] === 0) {
      arr.push("leftWall");
    }
    if (i === rows - 1 && j === cols - 1) {
      arr.push("destination");
    }
    if (i === userPosition[0] && j === userPosition[1]) {
      arr.push("currentPosition");
    }

    if (cheatMode && solution.has(String(i) + "-" + String(j))) {
      arr.push("sol");
    }
    return arr.join(" ");
  };

  const handleMove = (e, key) => {
    e.preventDefault();
    if (status !== "playing") {
      return;
    }
    const [i, j] = userPosition;
    if ((key === "ArrowUp" || key === "KeyW") && maze[i][j][0] === 1) {
      setUserPosition([i - 1, j]);
    }
    if ((key === "ArrowRight" || key === "KeyD") && maze[i][j][1] === 1) {
      setUserPosition([i, j + 1]);
    }
    if ((key === "ArrowDown" || key === "KeyS") && maze[i][j][2] === 1) {
      setUserPosition([i + 1, j]);
    }
    if ((key === "ArrowLeft" || key === "KeyA") && maze[i][j][3] === 1) {
      setUserPosition([i, j - 1]);
    }
  };

  const handleUpdateSettings = () => {
    setSize(Math.floor(Math.random() * (maxSize - minSize + 1) + minSize));
    setUserPosition([0, 0]);
    setStatus("playing");
    setGameId(gameId + 1);
  };

  return (
    <div className="App" onKeyDown={(e) => handleMove(e, e.code)} tabIndex={-1}>
      {/* <div className="setting">
        <label htmlFor="mazeSize">Size of maze (5-40):</label>
        <input type="number" name="mazeSize" min="5" max="40" defaultValue="10" />
      </div>
      <div className="setting">
        <button onClick={handleUpdateSettings}>Restart game with new settings</button>
      </div>
      <p>use WSAD or Arrow Keys to move</p>
      <div>
        <label htmlFor="cheatMode">Cheat mode</label>
        <input type="checkbox" name="cheatMode" onChange={(e) => setCheatMode(e.target.checked)} />
      </div> */}
      <table id="maze" className="mt-[15px] border-collapse w-[360px] h-[360px] sm:w-[450px] sm:h-[450px]">
        <tbody>
          {maze.map((row, i) => (
            <tr key={`row-${i}`}>
              {row.map((cell, j) => (
                <td key={`cell-${i}-${j}`} className={`${makeClassName(i, j)} w-[20px] h-[20px] p-0`}>
                  <div />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div id="keypad" className="mt-4">
        <section>
          <button aria-label="up arrow" onClick={(e) => handleMove(e, "ArrowUp")}>
            <UpArrowIcon className="w-4" />
          </button>
          <button aria-label="left arrow" onClick={(e) => handleMove(e, "ArrowLeft")}>
            <LeftArrowIcon className="w-4" />
          </button>
          <button aria-label="down arrow" onClick={(e) => handleMove(e, "ArrowDown")}>
            <DownArrowIcon className="w-4" />
          </button>
          <button aria-label="right arrow" onClick={(e) => handleMove(e, "ArrowRight")}>
            <RightArrowIcon className="w-4" />
          </button>
        </section>
      </div>
      {status !== "playing" && (
        <div className="info fixed" onClick={handleUpdateSettings}>
          <p>you won (click here to play again)</p>
        </div>
      )}
    </div>
  );
}
